
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'NotifyBox',
	components: {},
})
export default class Loader extends Vue {
	@Prop() type!: 'success' | 'warn' | 'error';
	get icon() {
		switch (this.type) {
			case 'success':
				return { 'gg-check': true };
			case 'warn':
				return { 'gg-info': true };
			case 'error':
				return { 'gg-danger': true };
		}
		return {};
	}
}

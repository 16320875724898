function isRequired(v: string | number) {
	if (!v && v != 0) return false;
	return !!v.toString().trim().length;
}

function validEmail(v: string) {
	return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(v).toLowerCase());
}

export { isRequired, validEmail };

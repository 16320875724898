import { ClientLocationCustomField } from '../models/ClientLocationCustomField.model';
import { CustomFieldResponse } from '../models/CustomFieldResponse.model';

export function getCustomFieldResponse(field: ClientLocationCustomField) {
	switch (field.ResponseTypeKey) {
		case 1:
			return field.Response_Boolean;
		case 2:
		case 4:
			return field.Response_Text ?? '';
		case 3:
			return field.Selected_ClientLocationCustomFieldResponseKeys[0] as number;
		default:
			return null;
	}
}
export function getCustomFieldResponseString(field: ClientLocationCustomField) {
	switch (field.ResponseTypeKey) {
		case 1:
			return field.Response_Boolean ? 'Agreed' : null;
		case 2:
		case 4:
			return field.Response_Text ?? '';
		case 3: {
			const key = field.Selected_ClientLocationCustomFieldResponseKeys[0];
			const res: CustomFieldResponse | undefined = field.CustomFieldResponses.find((response) => response.ClientLocationCustomFieldResponseKey == key);
			console.log('>>CF', key, field.CustomFieldResponses, res);
			if (res) return res.ResponseLabel;
			else return null;
		}
		// return field.Selected_ClientLocationCustomFieldResponseKeys[0] as number;
		default:
			return null;
	}
}
export function setCustomFieldResponse(field: ClientLocationCustomField, res: string | number | boolean | null) {
	switch (field.ResponseTypeKey) {
		case 1:
			field.Response_Boolean = res as boolean;
			break;
		case 2:
		case 4:
			field.Response_Text = res as string;
			break;
		case 3:
			field.Selected_ClientLocationCustomFieldResponseKeys = [res as number];
			break;
	}
}

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import PageNotFoundView from '@/views/404.view.vue';
// import Vue from 'vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/checkin/form/:orderKey/:clientCode/:onsite',
		name: 'CheckinView',
		component: () => import(/* webpackChunkName: "checkin-1" */ '../views/checkin/checkin.view.vue'),
		props: true,
		children: [
			{
				name: 'AppointmentsView',
				path: '/checkin/form/:orderKey/:clientCode/:onsite/appointments',
				component: () => import(/* webpackChunkName: "checkin-2" */ '../views/checkin/appointments.view.vue'),
				props: true,
			},
			{
				name: 'AdultCheckin',
				path: '/checkin/form/:orderKey/:clientCode/:onsite/consent',
				component: () => import(/* webpackChunkName: "checkin-3" */ '../views/checkin/adult.view.vue'),
				props: true,
			},
			{
				name: 'MinorsCheckin',
				path: '/checkin/form/:orderKey/:clientCode/:onsite/minors',
				component: () => import(/* webpackChunkName: "checkin-4" */ '../views/checkin/minors.view.vue'),
			},
			{
				name: 'ActivitySelection',
				path: '/checkin/form/:orderKey/:clientCode/:onsite/activities',
				props: true,
				component: () => import(/* webpackChunkName: "checkin-5" */ '../views/checkin/activitySelection.view.vue'),
			},
			{
				name: 'WaiverForms',
				path: '/checkin/form/:orderKey/:clientCode/:onsite/waivers',
				component: () => import(/* webpackChunkName: "checkin-6" */ '../views/checkin/waivers.vue'),
			},
			{
				name: 'Finished',
				path: '/checkin/form/:orderKey/:clientCode/:onsite/complete',
				component: () => import(/* webpackChunkName: "checkin-7" */ '../views/checkin/finished.view.vue'),
			},
			{
				name: 'Completed',
				path: '/checkin/form/:orderKey/:clientCode/:onsite/edit',
				component: () => import(/* webpackChunkName: "checkin-8" */ '../views/checkin/completedWaivers.view.vue'),
			},
			{
				name: 'Expired',
				path: '/checkin/form/:orderKey/:clientCode/:onsite/expired',
				component: () => import(/* webpackChunkName: "expired" */ '../views/checkin/expiredOrder.view.vue'),
			},
		],
	},
	{
		path: '/checkin/form/:clientCode/:onsite',
		redirect: (to) => {
			return { name: 'CheckinView', params: { ...to.params, orderKey: 0 }, query: { ...to.query } };
		},
	},
	{
		path: '/checkin/index/:onsite/:clientCode',
		redirect: (to) => {
			return { name: 'CheckinView', params: { ...to.params, orderKey: 0 }, query: { ...to.query } };
		},
	},
	{
		path: '/digital-tickets',
		name: 'DigitalTicketsView',
		component: () => import(/* webpackChunkName: "tickets" */ '../views/tickets/digitalTickets.view.vue'),
	},
	{
		path: '/manage/:clientLocationKey/:orderKey/',
		props: true,
		name: 'Order',
		component: () => import(/* webpackChunkName: "Order-1" */ '../views/manage/container.view.vue'),
		meta: {
			title: 'Manage booking',
		},
		children: [
			{
				path: '',
				props: true,
				name: 'OrderLanding',
				component: () => import(/* webpackChunkName: "OrderLanding" */ '../views/manage/order.view.vue'),
				meta: {
					title: 'Manage booking',
				},
			},
			{
				path: ':orderEntityTimeKey',
				props: (route) => Object.assign({}, route.query, route.params),
				name: 'OrderTime',
				component: () => import(/* webpackChunkName: "OrderTime" */ '../views/manage/time.view.vue'),
				children: [
					{
						path: 'warn/:next',
						props: true,
						name: 'OrderTimeWarn',
						component: () => import(/* webpackChunkName: "OrderTimeWarn" */ '../views/manage/time-warn.view.vue'),
						meta: {
							modal: true,
							canDeepLink: true,
							title: 'Manage booking',
						},
					},

					{
						path: 'edit',
						props: true,
						name: 'OrderTimeEdit',
						component: () => import(/* webpackChunkName: "OrderTimeEdit" */ '../views/manage/time-edit.view.vue'),
						meta: {
							modal: true,
							canDeepLink: true,
							title: 'Manage booking',
						},
					},
					{
						path: 'cancel',
						props: true,
						name: 'OrderTimeCancel',
						component: () => import(/* webpackChunkName: "OrderTimeCancel" */ '../views/manage/time-cancel.view.vue'),
						meta: {
							modal: true,
							canDeepLink: true,
							title: 'Manage booking',
						},
					},
					{
						path: 'refund',
						props: true,
						name: 'OrderTimeRefund',
						component: () => import(/* webpackChunkName: "OrderTimeRefund" */ '../views/manage/time-refund.view.vue'),
						meta: {
							modal: true,
							title: 'Manage booking',
						},
					},
					{
						path: 'payment',
						props: true,
						name: 'OrderTimePayment',
						component: () => import(/* webpackChunkName: "OrderTimePayment" */ '../views/manage/time-payment.view.vue'),
						meta: {
							modal: true,
							title: 'Manage booking',
						},
					},
					{
						path: 'confirm',
						props: true,
						name: 'OrderTimeConfirm',
						component: () => import(/* webpackChunkName: "OrderTimeConfirm" */ '../views/manage/time-confirm.view.vue'),
						meta: {
							modal: true,
							title: 'Manage booking',
						},
					},
				],
			},
		],
	},
	{
		path: '/portal/confirmation',
		props: true,
		name: 'ConfirmationView',
		component: () => import(/* webpackChunkName: "portal_confirmation" */ '../views/portal/confirmation.view.vue'),
	},
	{
		path: '/portal/:clientLocationKey/',
		props: true,
		name: 'PortalView',
		component: () => import(/* webpackChunkName: "portal" */ '../views/portal/container.view.vue'),
		children: [
			{
				path: ':type/:typekey',
				props: true,
				name: 'PackageView',
				component: () => import(/* webpackChunkName: "portal_package" */ '../views/portal/package.view.vue'),
			},
			{
				path: ':type/:typekey/book',
				props: true,
				name: 'BookView',
				component: () => import(/* webpackChunkName: "portal_book" */ '../views/portal/book.view.vue'),
				children: [
					{
						path: 'rate',
						props: true,
						name: 'rate',
						component: () => import(/* webpackChunkName: "portal_package_rate" */ '../views/portal/book/rate.view.vue'),
					},
					{
						path: 'date',
						props: true,
						name: 'date',
						component: () => import(/* webpackChunkName: "portal_package_date" */ '../views/portal/book/date.view.vue'),
					},
					{
						path: 'time',
						props: true,
						name: 'time',
						component: () => import(/* webpackChunkName: "portal_package_time" */ '../views/portal/book/time.view.vue'),
					},
					{
						path: 'addon',
						props: true,
						name: 'addon',
						component: () => import(/* webpackChunkName: "portal_package_addons" */ '../views/portal/book/addon.view.vue'),
					},
					{
						path: 'checkout',
						props: true,
						name: 'checkout',
						component: () => import(/* webpackChunkName: "portal_package_checkout" */ '../views/portal/book/checkout.view.vue'),
					},
				],
			},
		],
	},
	{
		path: '/404',
		name: 'PageNotFound',
		component: PageNotFoundView,
	},
	{
		path: '/:catchAll(.*)',
		name: 'NotFound',
		component: PageNotFoundView,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.hash) return { ...to, hash: '' }; // remove hash

	if (to.name == 'AdultCheckin' || to.name == 'AppointmentsView') {
		document.dispatchEvent(new Event('showTranslator'));
	} else {
		document.dispatchEvent(new Event('hideTranslator'));
	}
	//If we are navigating away from the editing modal, clear out changes
	if (from.meta.modal && !to.meta.modal && !to.params.success && orderModule.needsToAbandon) {
		orderModule.abandonOrderChanges();
		next();
		//If we are deeplinking in to a modal view, and the route does not allow it, go back to time
	} else if (to.meta.modal && !orderModule.isEditing && !to.meta.canDeepLink) {
		console.log('Overriding to OrderTime', to, from, orderModule.isEditing);
		next({ name: 'OrderTime', params: to.params });
	} else {
		next();
	}
});

import { nextTick } from 'vue';
import { orderModule } from '@/modules/order/orderModule.vuex-module';

const DEFAULT_TITLE = 'Default Title';
router.afterEach((to) => {
	nextTick(() => {
		document.title = (to.meta.title as string) || to.name?.toString() || DEFAULT_TITLE;
	});
});

export default router;

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_small_loader = _resolveComponent("small-loader")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["prog-btn action-btn-themed fw-medium", { 'is-loading': _ctx.showLoader }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClick'))),
    disabled: _ctx.disabled
  }, [
    (_ctx.showLoader)
      ? (_openBlock(), _createBlock(_component_small_loader, { key: 0 }))
      : _renderSlot(_ctx.$slots, "default", { key: 1 })
  ], 10, _hoisted_1))
}
import { clientModule } from '@/modules/client/client.vuex-module';
import { DirectiveBinding } from 'vue';

/**
 * Selects all input element text when input is focused
 */
export default {
	mounted(el: HTMLInputElement, binding: DirectiveBinding) {
		el.addEventListener('focus', () => {
			el.select();
		});
	},
};

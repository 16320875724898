import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "selected" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "flag-list"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_router_view)
    ]),
    (_ctx.flags.length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "flags",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.flagOpen = !_ctx.flagOpen))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              width: "40",
              src: _ctx.getCountryFlag(_ctx.flag)
            }, null, 8, _hoisted_2)
          ]),
          _createVNode(_Transition, { name: "slide" }, {
            default: _withCtx(() => [
              (_ctx.flagOpen)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flags, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.LanguageKey,
                        onClick: ($event: any) => (_ctx.flag = item)
                      }, [
                        _createElementVNode("img", {
                          width: "40",
                          src: _ctx.getCountryFlag(item)
                        }, null, 8, _hoisted_5)
                      ], 8, _hoisted_4))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
class LocalStorageService {
	localStorage: Storage;
	constructor() {
		this.localStorage = window.localStorage;
	}

	// eslint-disable-next-line
	get(key: string): any {
		if (this.isLocalStorageSupported) {
			const value = this.localStorage.getItem(key);
			if (value) return JSON.parse(value);
			else return null;
		}
		return null;
	}

	// eslint-disable-next-line
	set(key: string, value: any): boolean {
		if (this.isLocalStorageSupported) {
			try {
				this.localStorage.setItem(key, JSON.stringify(value));
				return true;
			} catch (e) {
				console.error('Unable to set LocalStorage for ' + key);
				console.error(e);
				return false;
			}
		}
		return false;
	}

	remove(key: string): boolean {
		if (this.isLocalStorageSupported) {
			try {
				this.localStorage.removeItem(key);
				return true;
			} catch (e) {
				console.error('Unable to remove LocalStorage for ' + key);
				console.error(e);
				return false;
			}
		}
		return false;
	}

	get isLocalStorageSupported(): boolean {
		return !!this.localStorage;
	}
}

export const localStorageService = new LocalStorageService();
